.arrowbox {
  border: 1px dashed transparent;
  padding: 2px;
  border-radius: 50%;
  position: relative;
  margin: 1px auto;
}

.bold {
  font-weight: 800;
}

.arrowbox:before {
  content: '';
  position: absolute;
  left: 83%;
  height: 0;
  width: 0;
  top: 4px;
  border-left: 3px solid;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.eastToWest {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.southToNorth {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.northToSouth {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.icon {
  width: 35px;
  height: 35px;
}

.iconContainer {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
}

.iconContainer.eastToWest {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.iconContainer.southToNorth {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.iconContainer.northToSouth {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.centerIcon {
  width: 25px;
  height: 25px;
  margin: auto;
}

.connector {
  margin-left: 30px;
}
