.outer {
  max-width: 900px;
  margin: 0 auto;
}

.flex-logos {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.elastic-logo {
  max-height: 40px;
  margin-left: 20px;
}

.spin {
  animation: loader 1s infinite;
}

.filters-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

.filter-item {
  flex: 1 1 calc(33.33% - 10px); /* Three items per row */
}

@keyframes loader {
  0% {
    rotate: 0deg;
  }

  100% {
    rotate: 360deg;
  }
}
